.experiance{
 margin-top: 60px;
 background-color: #f8f7f1;
 width: 100%;
 height: 750px;
    .expHeader{
        text-align: center;
        padding-top: 40px;
        h1{
            font-size: 55px;
            color: #0d2f3f;
        }
    }
    .expBody{
        display: flex;
        .expBodyLeft{
         flex: 5;
         display: flex;
         flex-flow: column;
        align-items:left;
        text-align: left;
        justify-content: space-around;
        gap: 100px;
        margin-top: 50px;
        
        .expBodyLeftSec1, .expBodyLeftSec2, .expBodyLeftSec3{
            margin-left: 300px;
        }
        }

        .expBodyCenter{
            flex: 1;
            margin-top: 50px;
            .circleIcon1, .circleIcon2, .circleIcon3{
                margin-left: 82px;
                font-size: 22px;
            }
            .circleIcon1{
                color: rgb(7, 143, 7);
            }
            .circleIcon2{
                color: rgb(209, 9, 9);
            }
            .circleIcon3{
                color: rgb(208, 236, 23);
                
            }
            p{
                margin-left: 90px;
            }
        }

        .expBodyRight{
            flex: 6;
            display: flex;
            flex-flow: column;
            align-items: start;
            justify-content: space-evenly;
            

            .expBodyRight1, .expBodyRight2, .expBodyRight3{
                width: 600px;
                height: auto;
                margin-top: 40px;
                text-align: left;
                h4{
                    font-size: 30px;
                    color: #0d2f3f;
                }
                p{
                   font-size: 18px; 
                   line-height: 30px;
                }
            }
        }
    }
}