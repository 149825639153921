.portfolio{
   width: 100%;
   height: 480px;
    .portTop{
     display: flex;
     justify-content:space-around ;
     margin-top: 30px;
     align-items: center;

     .portTopLeft{
        h1{
            font-size: 48px;
            color: #0d2f3f;
            font-weight: 700;
        }
        p{
            font-size: 18px;
            color: #0f5650;
        }
     }
     .portTopRight{
        a{
            font-size: 27px;
            color: #ef980d;
        }
     }
    }

    .portBottom{
        display: flex;
        justify-content: center;
        gap: 100px;
       
        img{
            width: 325px;
            height: 300px;
        }
        .portBottom1, .portBottom2{
            border-radius: 18px;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 9px -2px, 
                        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        }
        .portBottom3{
            border-radius: 18px;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 9px -2px,
                        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        }
    }
}