.header{
    position: sticky;
    top: 0;
    width: 100%;
    height: 100px;
    display: flex;
    color: #0d2f3f;
    background-color: #f8f7f1;
    font-family: 'Roboto Condensed', sans-serif;
    z-index: 999;
    opacity: 1;
    transform: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, 
                rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .headerLeft{
        flex: 3;
        display: flex;
        justify-content: end;
        align-items: center;
        h3{
            font-weight: bold;
        }
    
    }
    .headerCeneter{
        flex: 6;
        display: flex;
        justify-content: end;
        align-items: center;
        font-weight: 400;
      
        .topbar{
            display: flex;  
            gap: 10px;     
            cursor: pointer;
            a{
                text-decoration: none;
                color: #0d2f3f;
            }
        }
       
    }

    .headerRigth{
        flex: 3;
        margin-left: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        i{
            margin-bottom: 16px;
            color: #0e6c59;
            font-size: 20px;
        }
    }
}