.heroSection{
    width: 100%;
    height: 515px;
    background-color: #f8f7f1;
    display: flex;

    .heroLeft{
        flex: 4;
        display: flex;
        flex-flow: column;
        align-items: end;
        justify-content: space-around;
        text-align: center;
        h1{
            font-family: 'Varela', sans-serif; 
            font-size: 50px;
            font-weight: bold;
            color: #0d2f3f;
            
        }
        a{
            color: orange;
            font-size: 18px;
            padding-right: 40px;
        }
        .heroLeftTitle{
            display: flex;
            align-items: center;
            padding-right: 60px;
            h1{
                font-size: 65px;
            }
            h3{
                font-size: 25px;
            }
        }
    }

    .heroCenter{
        flex: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .heroCenterImg{
            padding-top: 135px;
        }
    }

    .heroRight{
        flex: 4;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;
        .heroRigthTitle{
            color: #0d2f3f;
            font-weight: normal;
        }
        .heroRigthBotttom{
            display: flex;
            flex-flow: column;
            align-items: center;
             h5{
                font-weight: 400;
                font-size: 18px;
            }
        }
        .rightBottomImg{
            width:50px;
            height:50px;
        }
    }
}