.service{
    display: flex;
    margin-bottom: 20px;

    .serviceLeft{
        flex: 6;
        display: flex;
        flex-flow: column;
        align-items: end;
        margin-top: 20px;
        padding-right: 20px;
        gap: 20px;

        .card{
            width: 550px;
            height: 150px;
            border-radius: 15px;
            
        }
        .card-body{
            display: flex;
            align-items: center;
            cursor: pointer;
            

            .cardLogoOne{
                width: 50px;
                height: 50px;
                font-size: 20px;
                color: white;
                background-color: #0d3f2fb3;
                text-align: center;
                padding-top: 10px;
                border-radius: 50%;
                margin-left: 20px;
                margin-right: 30px;
            }
            .cardLogoTwo{
                width: 50px;
                height: 50px;
                font-size: 25px;
                color: white;
                background: rgb(238, 192, 72);
                text-align: center;
                padding-top: 7px;
                border-radius: 50%;
                margin-left: 20px;
                margin-right: 30px;
            }
            .cardLogoThree{
                width: 50px;
                height: 50px;
                font-size: 20px;
                color: white;
                background: rgb(242, 100, 64);
                text-align: center;
                padding-top: 10px;
                border-radius: 50%;
                margin-left: 20px;
                margin-right: 30px;
                
                
            }

            .cardTitle{
                color: #0d3f2fb3;
                h2{
                    font-size: 30px;
                    font-weight: 700;
                }
                h6{
                    font-size: 22px;
                }
            }
        }
    }

    .card:hover{
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px -1px, 
                    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }

    .serviceRigth{
        flex: 6;
        display: flex;
        flex-flow: column;
        padding-left: 10px;
        margin-top: 20px;
        .serviceTitle{
            h1{
                color: #0d2f3f;
                font-size: 45px;
                font-weight: 500;
            }
            
        }
        .serviceDesc{
            margin-top: 20px;
            p{
                color: #0d2f3f;
                font-size: 22px;
                line-height: 40px;
                font-weight: 500;
            }
        }
        .serviceNbr{
            display: flex;
            gap: 100px;
            margin-top: 45px;
            .serviceNbrOne{
                display: flex;
                flex-flow: column;
                align-items: center;
                text-align: center;
                justify-content: center;
                h1{
                    font-size: 50px;
                    padding-left: 5px;
                }
                p{
                   font-size: 22px; 
                }
            }

            .serviceNbrSecond{
                display: flex;
                flex-flow: column;
                align-items: center;
                text-align: center;
                justify-content: center;
                h1{
                    font-size: 50px;
                    padding-left: 5px;
                }
                p{
                   font-size: 22px; 
                }
            }


        }
    }
}