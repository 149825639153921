.footer{
    display: flex;
    width: auto;
    height: 400px;
    .footerLeft{
        flex: 6;
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-top: 100px;
        gap: 40px;

        h1{
            font-size: 45px;
            font-weight: 700;
            margin-left: 170px;
            font-family: 'Josefin Sans', sans-serif;
        }
        .start{
            margin-right: 88px;
            a{
                color: orange;
            }
        }
    }

    .footerRight{
        flex: 6;
        text-align: center;
        display: flex;
        flex-flow: column;
        h1{
            font-size: 22px;
            font-weight: normal;
            margin-top: 120px;
            margin-right: 300px;
        }
        p{
            font-size: 15px;
            font-weight: normal;
            margin-top: 5px;
            margin-right: 230px;
        }
        a{
            text-decoration: none;
            cursor: pointer;
            margin-top: 7px;
            text-align: center;
            margin-right: 340px;
            color: rgba(11, 49, 31, 0.438);
        }
    }
}